@import '../../../global.scss';

.footer-home {
  background-color: $blue-grey;
  padding: 35px;
  color: white;

  .footer-components {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .footer-title {
    font-size: 40px;
    font-weight: 600;
    .username {
      font-weight: 700;
    }
  }

  .footer-links {
    display: flex;
    justify-content: center;
    padding-top: 17px;
  }
}
.footer-copyright {
  padding: 5px 0px 20px 0px;
  background-color: $blue-grey;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .copyright-text {
    padding-left: 5px;
  }
}

@media (max-width: 768px) {
  .footer-home {
    padding: 30px;

    .footer-components {
      justify-content: center;
    }

    .footer-links {
      padding-top: 15px;
    }

    .footer-title {
      font-size: 30px;
      font-weight: 600;
      text-align: center;

      .username {
        font-weight: 700;
      }
    }
  }
}
