@import '../../../global.scss';

.home-landing {
  padding: 40px 0px;
  align-items: center;
  color: $common-black;

  .landing-text {
    padding: 70px 0px;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: 1.25;
  }

  .landing-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;

    img {
      height: 300px;
      border-radius: 150px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .home-landing {
    padding: 30px 0px;
    .landing-text {
      padding: 20px 0px 40px 0px;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 1.15;
      text-align: center;
    }
    .landing-breakline-display {
      display: none;
    }
    .landing-image {
      padding: 20px 0px;
      img {
        height: 200px;
        border-radius: 100px;
      }
    }
  }
}
